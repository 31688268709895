/*
	init.js v2.0
	Wezom wTPL v4.0.0
*/
window.wHTML = (function($){

	/* Приватные переменные */

		var varSeoIframe = 'seoIframe',
			varSeoTxt = 'seoTxt',
			varSeoClone = 'cloneSeo',
			varSeoDelay = 200;

	/* Приватные функции */

		/** проверка типа данных на объект
		 * @param {object} data - description
		 */
		var _isObject = function(data) {
			var flag = (typeof data == 'object') && (data+'' != 'null');
			return flag;
		},

		/* создание нового элемента элемента */
		_crtEl = function(tag, classes, attrs, jq) {
			var tagName = tag || 'div';
			var element = document.createElement(tagName);
			var jQueryElement = jq || true;
			// если классы объявлены - добавляем
			if (classes) {
				var tagClasses = classes.split(' ');
				for (var i = 0; i < tagClasses.length; i++) {
					element.classList.add(tagClasses[i]);
				}
			}
			// если атрибуты объявлены - добавляем
			if (_isObject(attrs)) {
				for (var key in attrs) {
					var val = attrs[key];
					element[key] = val;
				}
			}
			// возвращаем созданый елемент
			if (jQueryElement) {
				return $(element);
			} else {
				return element;
			}
		},

		/* создаем iframe для сео текста */
		_seoBuild = function(wrapper) {
			var seoTimer;
			// создаем iframe, который будет следить за resize'm окна
			var iframe = _crtEl('iframe', false, {id: varSeoIframe, name: varSeoIframe});
			iframe.css({
				'position':'absolute',
				'left':'0',
				'top':'0',
				'width':'100%',
				'height':'100%',
				'z-index':'-1'
			});
			// добавляем его в родитель сео текста
			wrapper.prepend(iframe);
			// "прослушка" ресайза
			seoIframe.onresize = function() {
				clearTimeout(seoTimer);
				seoTimer = setTimeout(function() {
					wHTML.seoSet();
				}, varSeoDelay);
			};
			// вызываем seoSet()
			wHTML.seoSet();
		};

	/* Публичные методы */

		function Methods(){}

		Methods.prototype = {

			/* установка cео текста на странице */
			seoSet: function() {
				if ($('#'+varSeoTxt).length) {
					var seoText = $('#'+varSeoTxt);
					var iframe = seoText.children('#'+varSeoIframe);
					if (iframe.length) {
						// если iframe сущствует устанавливаем на место сео текст
						var seoClone = $('#'+varSeoClone);
						if (seoClone.length) {
							// клонеру задаем высоту
							seoClone.height(seoText.outerHeight(true));
							// тексту задаем позицию
							seoText.css({
								top: seoClone.offset().top
							});
						} else {
							// клонера нету - бьем в колокола !!!
							console.error('"'+varSeoClone+'" - не найден!');
						}
					} else {
						// если iframe отсутствует, создаем его и устанавливаем на место сео текст
						_seoBuild(seoText);
					}
				}
			},

			/* magnificPopup inline */
			mfi: function() {
				$('.mfi').magnificPopup({
					type: 'inline',
					closeBtnInside: true,
					removalDelay: 300,
					mainClass: 'zoom-in'
				});
			},

			/* magnificPopup ajax */
			mfiAjax: function() {
				$('body').magnificPopup({
					delegate: '.mfiA',
					callbacks: {
						elementParse: function(item) {
							this.st.ajax.settings = {
								url: item.el.data('url'),
								type: 'POST',
								data: (typeof item.el.data('param') !== 'undefined') ? item.el.data('param') : ''
							};
						},
						ajaxContentAdded: function(el) {
							wHTML.validation();
						}
					},
					type: 'ajax',
					removalDelay: 300,
					mainClass: 'zoom-in'
				});
			},

			/* оборачивание iframe и video для адаптации */
			wTxtIFRAME: function() {
				var list = $('.wTxt').find('iframe').add($('.wTxt').find('video'));
				if (list.length) {
					// в цикле для каждого
					for (var i = 0; i < list.length; i++) {
						var element = list[i];
						var jqElement = $(element);
						// если имеет класс ignoreHolder, пропускаем
						if (jqElement.hasClass('ignoreHolder')) {
							continue;
						}
						if (typeof jqElement.data('wraped') === 'undefined') {
							// определяем соотношение сторон
							var ratio = parseFloat((+element.offsetHeight / +element.offsetWidth * 100).toFixed(2));
							if (isNaN(ratio)) {
								// страховка 16:9
								ratio = 56.25;
							}
							// назнчаем дату и обрачиваем блоком
							jqElement.data('wraped', true).wrap('<div class="iframeHolder ratio_' + ratio.toFixed(0) + '" style="padding-top:'+ratio+'%;""></div>');
						}
					}
					// фиксим сео текст
					this.seoSet();
				}
			}
		};

	/* Объявление wHTML и базовые свойства */

	var wHTML = $.extend(true, Methods.prototype, {});

	return wHTML;

})(jQuery);




jQuery(document).ready(function($) {

	// поддержка cssanimations
	transitFlag = Modernizr.cssanimations;

	// очитска localStorage
	localStorage.clear();

	// сео текст
	wHTML.seoSet();

	// magnificPopup inline
	wHTML.mfi();

	// magnificPopup ajax
	wHTML.mfiAjax();

	// валидация форм
	wHTML.validation();


	 //ggl_map
	if ($("#ggl_map").length) {

		/** Данные карты, которые беруться с элемента `#ggl_map` из пользовательских атрибутов `data-...` */
		var mapdata = [parseFloat($("#ggl_map").attr('data-map-x'), 10), parseFloat($("#ggl_map").attr('data-map-y'), 10), parseInt($("#ggl_map").attr('data-map-z'), 10), $('#ggl_map').attr('data-map-title'), $('#ggl_map').attr('data-map-icon'), $('#mapContent').html()];


		/** Функция Google карты */
		function loadGoogleMap() {


			var lat = mapdata[0];
			var lng = mapdata[1];
			var markerLatlng = new google.maps.LatLng(lat, lng);
			// console.log(lat, lng);
			// lng = 21.130;
			lng = 33.37;
			// console.log(lat, lng);
			var myLatlng = new google.maps.LatLng(lat, lng);
			var styles = [{}]

			/** набор опций */
			var myOptions = {
				zoom: mapdata[2],
				scrollwheel: false,
				center: myLatlng,
				// center: {lat: -33, lng: 151},                
				zoomControl: true,
				zoomControlOptions: {
				  style: google.maps.ZoomControlStyle.SMALL
				},
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};


			var map = new google.maps.Map(document.getElementById("ggl_map"), myOptions);
			map.setOptions({styles: styles});

			var contentString = mapdata[5];

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});


			// var myLatLng = new google.maps.LatLng(mapdata[0], 21.1247195);

			if ($(window).width() > 768) {
				var marker = new google.maps.Marker({
					position: markerLatlng,
					map: map,
					icon: mapdata[4],
					title: mapdata[3]
				});                
			}
			else {
				var marker = new google.maps.Marker({
					position: myLatlng,
					map: map,
					icon: mapdata[4],
					title: mapdata[3]
				});    
			}

		}

		/** ### loadGoogleMap() 
		 Инициализация и resize функции запуска Google rfhns		 
		 * @name WDOCS_FROM */

			$(window).load(function() {
				loadGoogleMap();
			});

			$(window).resize(function() {
				loadGoogleMap();
				wHTML.seoSet();
			});

			// WDOCS_TO
	}


	//anim_menu_btn
	$('.js-anim_menu_btn').click(function(){
		$(this).toggleClass('open');
	});


	$(window).load(function() {
		// оборачивание iframe и video для адаптации
		wHTML.wTxtIFRAME();

		$('.js-search--btn').on('click', function(event) {
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
			}
			else {
				$(this).addClass('active');               
			}
			if ($('.wHeader__bottom').hasClass('show')) {
				$('.wHeader__bottom').removeClass('show');
			}
			else {
				$('.wHeader__bottom').addClass('show');                
			}
		});

		//mmenu
	       
		$("#js-menu").mmenu({
			"offCanvas": {
			  "position": "right"
		   },
		   "extensions": [
			  "theme-dark"
		   ],
		   counters: true,
			dividers: {
				add: true,
				addTo: "[id*='contacts-']",
				fixed: true
			},
			// searchfield: {
			// 	resultsPanel: true
			// },
			navbar: {
			    title: "Меню"
			},
			navbars: {
				height: 3,        
				content:  [ 
				'<div class="wSearch__item">'+
				'<div data-form="true" class="wForm wFormDef" novalidate="novalidate">'+
				'<div class="wFormRow">'+
				'<div class="wFormInput">'+
				'<input class="wInput" required="" type="text" name="demo_required_input" id="demo_required_input" placeholder="Поиск" aria-required="true">'+
				'</div>'+
				'</div>'+
				'<div class="wFormRow">'+
				'<button class="wSubmit wBtn w_primary"> <span>Найти</span></button>'+
				'</div>'+
				'</div>'+
				'</div>'			
				]
			}
		}); 

		var api_mmenu = $('#js-menu').data('mmenu');

		$('#js-menu').on('click', '.mfiA', function(event) {
			api_mmenu.close();
		});
		      

		// api_mmenu.bind('opened', function () {
		// 	wHTML.seoSet();
		// });
		
		// api_mmenu.bind('closed', function () {
		// 	console.log(999);
		// 	wHTML.seoSet();
		// });
	// $('.wHeader__menu_btn') .on('click', function(event) {
	// 	event.preventDefault(); 
	// });

		/** ### js-wArticles__slider
		 Инициализация слайдера, если он существует
		 * @name WDOCS_FROM
		*/
		if ($('.js-wArticles__slider').length) {

			var Item_active = $('.wArticles__slider > div:nth-child(2)').addClass('active');  

			$('.js-wArticles__slider').carouFredSel({
				// items: 5,
				width: 1100,
				items: {
					visible: 3,
					height: 'auto'
				},
				height: 'auto', 
				auto: {
					play: true,
					timeoutDuration: 3000
				},
				prev: ".js-s_prev",
				next: ".js-s_next",
				swipe: {
					onTouch: true
				},
				onCreate: function(data) {
					Item_active.addClass('active');
				},
				scroll: {
					items: 1,
					fx: "scroll",                   
					duration: 1200,
					pauseOnHover: true,
					onBefore: function(data) {
						$('.wArticles__slider > div').removeClass('active');
						$(data.items.visible[1]).addClass('active');
					},             
				},
			}, {
				transition: transitFlag

			});
		}
		//WDOCS_TO

		/** ### wLeft__slider
		 Инициализация слайдера, если он существует
		 * @name WDOCS_FROM
		*/
		if ($('.wLeft__slider').length) {

			var Item_active = $('.wLeft__slider > div:first-child()').addClass('active');  


			$('.js-wLeft__slider').carouFredSel({
				// items: 5,
				width: "auto",
				items: {
					visible: 1,
					height: 'auto'
				},
				height: 'auto', 
				auto: {
					play: true,
					timeoutDuration: 3000
				},
				prev: ".js-s_prev",
				next: ".js-s_next",
				swipe: {
					onTouch: true
				},
				onCreate: function(data) {
					Item_active.addClass('active');
				},
				scroll: {
					items: 1,
					fx: "scroll",                   
					duration: 1200,
					pauseOnHover: true,
					onBefore: function(data) {
						$('.wLeft__slider > div').removeClass('active');
						$(data.items.visible[0]).addClass('active');
					},             
				},
			}, {
				transition: transitFlag

			});

			// $(document).resize(function() {

			// 	 $(".js-wLeft__slider").trigger("updateSizes"); 
			// });
		}
		//WDOCS_TO



		/** ### wSmall_img__slider
		 Инициализация слайдера, если он существует
		 * @name WDOCS_FROM
		*/
		if ($('.wSmall_img__slider').length) {

			$('.js-wSmall_img__slider').carouFredSel({
				// items: 5,
				width: "100%",
				items: {
					// visible: 2,
					height: 'auto'
				},
				height: 'auto', 
				auto: {
					play: false,
					timeoutDuration: 3000
				},
				prev: ".js-i_prev",
				next: ".js-i_next",
				swipe: {
					onTouch: true
				},
				scroll: {
					items: 1,
					fx: "scroll",                   
					duration: 1200,
					pauseOnHover: true,           
				},
			}, {
				transition: transitFlag

			});
		}
		//WDOCS_TO


		
		//Кнопка для раскрытия каталога (слева)
		if($('.wHeader__catalog_btn').length) {
			$('.wHeader__catalog_btn').on('click', function(event) {
				$(this).find('.wHeader__catalog_btn--list').slideToggle(400);
			});  

			$(document).on('click', function(e) {
				if ($(e.target).closest('.wHeader__catalog_btn').length == 0) {
					$('.wHeader__catalog_btn--list').slideUp(400);
				}
			});      
		}

		//scroll_top
		var top_show = 200;
		var delay = 1000;

		// $(window).scroll(function() {
		//     if ($(this).scrollTop() > top_show) $('.scroll_top').fadeIn();
		//     else $('.scroll_top').fadeOut();
		// });
		
		$('#js-scroll_top').on('touchstart mousedown', function(e) { // При клике по кнопке "Наверх" попадаем в эту функцию
			e.preventDefault();
			$('body, html').animate({
				scrollTop: 0
			}, delay);
		});
		

		// сео текст			
		wHTML.seoSet();


	});

	$(window).resize(function() {
		wHTML.seoSet();
	});
	

});
